import styles from '#components/menu/Navigation/Navigation.module.scss';

import Button from '#components/ui/Button/index';

import { prepareUrl } from '#utils/prepareUrl';
import FeaturesSelectorList from '#components/menu/Navigation/FeaturesSelectorList';
import { useStore } from '@nanostores/react';
import { openHamburger, openFeatures } from '#store/headerNavigation';
import useResponsive from '#hooks/useResponsive';
import { scrollToIdElement } from '#utils/scroll';

type Props = {
  headerStyle: string;
  pathname: string;
  navigationContent: Record<string, string | string[]>;
  lang: string;
};

const Navigation = ({ headerStyle, pathname, navigationContent, lang }: Props) => {
  const preparedLink = prepareUrl('/login', lang);
  const preparedLinkMob = prepareUrl('/email', lang);
  const $openHamburger = useStore(openHamburger);
  const $openFeatures = useStore(openFeatures);
  const isDesktop = useResponsive('desktop');
  const otherPages = ['terms', 'policy', '404', 'check-lost-phone'];

  const toggleFeatures = (status: boolean) => {
    openFeatures.set(status);
  };

  const handleCloseHamburger = (element: string) => {
    otherPages.forEach((el) => {
      if (pathname.includes(el)) {
        window.location.assign(prepareUrl(`/#${element}`, lang, false));
      } else {
        scrollToIdElement(element);
      }
    });
    toggleFeatures(false);
    openHamburger.set(false);
  };

  return (
    <ul
      className={`${styles.navigation} ${($openHamburger || $openFeatures) && styles.isOpened} ${headerStyle === 'dark' && styles.dark}`}
    >
      <li className={styles.list}>
        <span
          className={styles.link}
          role="presentation"
          onClick={() => handleCloseHamburger('how-it-works')}
        >
          {navigationContent.how}
        </span>
      </li>

      <li
        className={`${styles.list} ${styles.features} ${$openFeatures && styles.isOpened}`}
        onMouseEnter={() => isDesktop && toggleFeatures(true)}
        onMouseLeave={() => isDesktop && toggleFeatures(false)}
      >
        <span
          className={`${styles.link} ${styles.features}`}
          role="presentation"
          onClick={() => toggleFeatures(!$openFeatures)}
        >
          {navigationContent.features}
        </span>
        {$openFeatures && (
          <FeaturesSelectorList lang={lang} features={navigationContent.featuresList} />
        )}
      </li>

      <li className={styles.list}>
        <span
          className={styles.link}
          role="presentation"
          onClick={() => handleCloseHamburger('questions')}
        >
          {navigationContent.faq}
        </span>
      </li>

      <li className={styles.list}>
        <a href={`${navigationContent.blogLink}${navigationContent.root}`} className={styles.link}>
          {navigationContent.blog}
        </a>
      </li>

      <li className={styles.list}>
        <Button
          text={navigationContent.login}
          styleType={headerStyle.includes('dark') ? 'darkMob' : 'lightMob'}
          onClick={() => window.open(preparedLink, '_blank')}
        />
      </li>

      <li className={styles.list}>
        <Button
          text={navigationContent.button}
          styleType="orangeMob"
          onClick={() => (window.location.href = preparedLinkMob)}
        />
      </li>
    </ul>
  );
};

export default Navigation;
