import config from '#config';

export const initI18Next = (i18next: Record<string, any>, lang: string) => {
  i18next.init({
    lng: lang || 'en',
    fallbackLng: config.LANGS,
    interpolation: {
      escapeValue: false,
    },
  });
};
